button > i {
  pointer-events: none;
}

.spinner {
  animation: spin infinite 2s linear;
}

.modal-80w {
  width: 80%;
  max-width: none !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.empty-header, .empty-footer {
  height:145px;
  }
  .header-print, .footer-print {
  position: fixed;
  height:145px;
  top: 0;
  }

@media print {
  #page1 {
    page-break-after: always;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .nav-bar-fixed {
    display: none !important;
  }

  .button_print {
    display: none;
  }

  .fix-bottom {
    position: fixed;
    margin-left: -100px;
    left: 50%;
    bottom: 0px;
  }
  .fix-top {
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
  }

  .box-img {
    position: relative;
    width: 100%;
    top: 10em;
    left: 0px;
    right: 0px;
  }
}
