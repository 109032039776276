.btn-open-doc-legis {
  border: none;
  background: none;
  outline: none !important;
}

blockquote blockquote {
  border-left: none;
  font-style: italic;
  margin-left: 30px;
}

.content-info-ref{
  margin: 50px 0 -10px 0;
  height: auto;
  min-width: 60%;
}
